import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import commonEN from './translations/en/common.json';
import authEN from './translations/en/auth.json';
import errorEN from './translations/en/error.json';
import notFoundEN from './translations/en/page_not-found.json';
import orderEN from './translations/en/page-order.json';
import treatmentsEN from './translations/en/page-treatments.json';
import componentEN from './translations/en/component.json';
import commonFR from './translations/fr/common.json';
import authFR from './translations/fr/auth.json';
import errorFR from './translations/fr/error.json';
import notFoundFR from './translations/fr/page_not-found.json';
import orderFR from './translations/fr/page-order.json';
import treatmentsFR from './translations/fr/page-treatments.json';
import componentFR from './translations/fr/component.json';

//Creating object with the variables of imported translation files
const resources = {
  fr: {
    common: commonFR,
    auth: authFR,
    notFound: notFoundFR,
    error: errorFR,
    order: orderFR,
    treatments: treatmentsFR,
    component: componentFR
  },
  en: {
    common: commonEN,
    auth: authEN,
    notFound: notFoundEN,
    error: errorEN,
    order: orderEN,
    treatments: treatmentsEN,
    component: componentEN
  }
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', //default language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
