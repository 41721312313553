import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.module.scss';

type props = {
  isOpened: boolean;
  closeOnOutsideClick?: boolean;
  onOutsideClick: () => void;
  children: JSX.Element;
};

const Modal = ({ isOpened, closeOnOutsideClick = true, onOutsideClick, children }: props) => {
  const [shouldRender, setRender] = useState(isOpened);
  useEffect(() => {
    if (isOpened) {
      setRender(true);
    }
  }, [isOpened]);

  const onAnimationEnd = () => {
    if (!isOpened) {
      setRender(false);
    }
  };

  return (
    <>
      {shouldRender &&
        ReactDOM.createPortal(
          <>
            <div
              className={[
                styles['modal__background'],
                [
                  isOpened
                    ? styles['modal__background--fade-in-modal']
                    : styles['modal__background--fade-out-modal']
                ]
              ].join(' ')}
              onClick={() => {
                if (closeOnOutsideClick) {
                  onOutsideClick();
                }
              }}
              onAnimationEnd={onAnimationEnd}></div>
            <div
              className={[
                styles['modal'],
                [isOpened ? styles['modal--opened'] : styles['modal--closed']]
              ].join(' ')}
              data-cy={'modal'}>
              {children}
            </div>
          </>,
          document.getElementById('dialog-root') as Element
        )}
    </>
  );
};

export default Modal;
