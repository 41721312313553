import React, { useState } from 'react';
import styles from './login-page.module.scss';
import {
  AttentionBox,
  Box,
  Button,
  Link,
  Text,
  TextField
} from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authActions } from '../../../store/auth/auth.reducers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  attentionBoxErrorSelector,
  fieldErrorSelector
} from '../../../store/feedback/feedback.selector';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { ColorPropsEnum } from '../../../enum/color.enum';
import Onboarding from './onboarding/Onboarding';

type OptionProps = {
  variant: ColorPropsEnum;
  helperText: string;
};

const LoginPage = () => {
  const { t } = useTranslation(['auth']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [emailFieldOptions, setEmailFieldOptions] = useState<OptionProps>({
    variant: ColorPropsEnum.DEFAULT,
    helperText: ''
  });
  const [passwordFieldOptions, setPasswordFieldOptions] = useState<OptionProps>({
    variant: ColorPropsEnum.DEFAULT,
    helperText: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Selectors
  const attentionBoxError = useAppSelector(attentionBoxErrorSelector);
  const fieldError = useAppSelector(fieldErrorSelector);

  const handleEmailOnBlur = () => {
    if (emailFieldOptions.variant != ColorPropsEnum.DEFAULT)
      setEmailFieldOptions({
        variant: ColorPropsEnum.DEFAULT,
        helperText: ''
      });
  };

  const handlePasswordOnBlur = () => {
    if (passwordFieldOptions.variant != ColorPropsEnum.DEFAULT)
      setPasswordFieldOptions({
        variant: ColorPropsEnum.DEFAULT,
        helperText: ''
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email.length > 0 && password.length > 0) {
      try {
        setIsLoading(true);
        await dispatch(authActions.login({ username: email, password })).unwrap();
      } finally {
        setIsLoading(false);
      }
    } else {
      if (email.length === 0) {
        setEmailFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyEmail')
        });
      }
      if (password.length === 0) {
        setPasswordFieldOptions({
          variant: ColorPropsEnum.DANGER,
          helperText: t('error.emptyPassword')
        });
      }
    }
  };

  if (fieldError) {
    setEmailFieldOptions({
      variant: ColorPropsEnum.DANGER,
      helperText: fieldError.message
    });
  }

  const handleCloseErrorMessage = () => {
    dispatch(feedbackActions.resetAttentionBoxError());
  };

  const handleClickRegister = () => {
    navigate('/register');
  };

  return (
    <div className={styles['login-page']}>
      <header className={styles['login-page__header']}>
        <img
          src="/logo/logo-circle-title-purple.svg"
          alt="Circle logo"
          className={styles['login-page__header__logo']}
        />
      </header>

      <section className={styles['login-page__section']}>
        <Box
          padding="spacing-0"
          className={styles['login-page__section__box']}
          radius="large"
          color={ColorPropsEnum.WHITE}>
          <Onboarding />
          <div className={styles['login-page__section__box__aside']}>
            <Text
              label={t('title')}
              type="title"
              size="m"
              textAlign="center-align"
              color={ColorPropsEnum.PRIMARY}
              className={styles['login-page__section__box__aside__attention-box']}
              bold={true}
            />
            {attentionBoxError && (
              <AttentionBox
                withoutIcon={false}
                mode="danger"
                className={styles['login-page__section__box__aside__attention-box__message']}
                onClose={handleCloseErrorMessage}
                text={attentionBoxError.message}
                data-cy="login-form-attention-box"
              />
            )}
            <form
              noValidate
              id="login-page__section__form"
              className={styles['login-page__section__box__aside__form']}
              onSubmit={handleSubmit}>
              <TextField
                label={t('email')}
                id="email"
                value={email}
                placeholder="email@circle.com"
                type="email"
                variant={emailFieldOptions.variant}
                helperText={emailFieldOptions.helperText}
                className={styles['login-page__section__box__aside__form__input']}
                onChange={(evt: Event) => setEmail((evt.target as HTMLInputElement).value)}
                onBlur={handleEmailOnBlur}
                radius="full"
                data-cy="login-form-email"
              />
              <TextField
                label={t('password')}
                id="password"
                value={password}
                placeholder={t('password')}
                type="password"
                variant={passwordFieldOptions.variant}
                helperText={passwordFieldOptions.helperText}
                className={styles['login-page__section__box__aside__form__input']}
                onChange={(evt: Event) => setPassword((evt.target as HTMLInputElement).value)}
                onBlur={handlePasswordOnBlur}
                radius="full"
                data-cy="login-form-password"
              />
              <Link
                className={styles['login-page__section__box__aside__form__forgotten-password']}
                color={ColorPropsEnum.PRIMARY}
                href="#"
                label={t('forgotPassword')}
                size="s"
                data-cy="login-form-forgot-password-link"
              />

              <div className={styles['login-page__section__box__aside__form__button']}>
                <Button
                  label={t('buttonSubmit')}
                  isLoading={isLoading}
                  type="submit"
                  variant={ColorPropsEnum.SUCCESS}
                  className={styles['login-page__section__box__aside__form__button__submit']}
                  data-cy="login-form-submit-button"
                />
              </div>
            </form>
          </div>
        </Box>

        <footer className={styles['login-page__section__footer']}>
          <Text
            className={styles['login-page__section__footer__text']}
            label={t('noAccount')}
            size="s"
            color={ColorPropsEnum.PRIMARY}
          />
          <Button
            category="secondary"
            label={t('signupButton')}
            size="s"
            onClick={handleClickRegister}
            data-cy="login-register-button"
          />
        </footer>
      </section>
    </div>
  );
};

export default LoginPage;
