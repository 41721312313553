import { ColorPropsEnum } from '../../../../../enum/color.enum';
import styles from './treatment-type-radio-list.module.scss';
import { Text } from '@platform-storybook/circlestorybook';

type option = {
  value: string;
  isDisabled?: boolean;
  logoClass?: string;
  label: string;
};

type props = {
  options: option[];
  selectedValue: string;
  onClick: (value: string) => void;
};

const TreatmentTypeRadioList = ({ options, selectedValue, onClick }: props) => {
  const handleClickRadio = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !event.currentTarget.classList.contains(styles['treatment-type-radio-list__option--disabled'])
    ) {
      const value = event.currentTarget.getAttribute('data-value')!;
      onClick(value);
    }
  };

  return (
    <div className={styles['treatment-type-radio-list']}>
      {options.map((option, index) => (
        <div
          className={[
            styles['treatment-type-radio-list__option'],
            option.value === selectedValue && styles['treatment-type-radio-list__option--selected'],
            option.isDisabled && styles['treatment-type-radio-list__option--disabled']
          ].join(' ')}
          onClick={handleClickRadio}
          data-value={option.value}
          key={index}>
          {option.logoClass && <div className={option.logoClass}></div>}
          <Text
            size="xs"
            textAlign="center-align"
            label={option.label}
            color={ColorPropsEnum.PRIMARY}
            className={styles['treatment-type-radio-list__option__text']}
          />
        </div>
      ))}
    </div>
  );
};

export default TreatmentTypeRadioList;
