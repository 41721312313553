import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../store/hooks.tsx';
import { BubblePicto, Skeleton } from '@platform-storybook/circlestorybook';

import ProductsManager, {
  MappedProducts
} from '../../../../../features/order-manager/products-manager.tsx';
import { FullProduct, Product } from '../../../../../models/product.tsx';

import { useGetCommonTypesQuery } from '../../../../../services/common-types-api.services.ts';
import {
  useGetAllChairsideProductsQuery,
  useLazyGetOneProductQuery
} from '../../../../../services/products-api.services.ts';

import { feedbackActions } from '../../../../../store/feedback/feedback.reducer.tsx';
import { ordersActions } from '../../../../../store/orders/orders.reducer.tsx';
import { mapActions } from '../../../../../store/map/map.reducer.tsx';

import { Family, ProductCategory } from '../../../../../enum/product.enum.ts';
import { ColorPropsEnum } from '../../../../../enum/color.enum.ts';
import { ToastType } from '../../../../../enum/feedback.ts';
import { ComponentType, ToothShadeEnum } from '../../../../../enum/component.ts';
import { MapModeEnum } from '../../../../../enum/map.enum.ts';
import { Shade } from '../../../../../models/common-types.tsx';

import { getLocalizedProperty, getMessageError } from '../../../../../utils/utils.tsx';
import styles from './map-tools.module.scss';

type Props = {
  currentProduct?: FullProduct;
  selectedShade?: ToothShadeEnum;
  toolMode?: MapModeEnum;
};

const MapTools = ({ currentProduct, selectedShade, toolMode }: Props) => {
  const dispatch = useAppDispatch();

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const [getOneProduct] = useLazyGetOneProductQuery();
  const [mappedProducts, setMappedProducts] = useState<MappedProducts | undefined>();
  const [families, setFamilies] = useState<Family[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [productsManager] = useState<ProductsManager>(() => new ProductsManager());

  const { data: commonTypes, isSuccess: isSuccessGetCommonTypes } = useGetCommonTypesQuery();
  const {
    data: products,
    isSuccess: isSuccessGetProducts,
    isLoading: isLoadingGetProducts
  } = useGetAllChairsideProductsQuery();

  const familiesObjectEnum: { [key in keyof typeof Family]: Family } | undefined =
    commonTypes?.families;
  const categoriesObjectEnum:
    | { [key in keyof typeof ProductCategory]: ProductCategory }
    | undefined = commonTypes?.productCategories;

  if (isSuccessGetProducts && isSuccessGetCommonTypes && !families.length && !categories.length) {
    setFamilies(familiesObjectEnum ? Object.values(familiesObjectEnum) : []);
    setCategories(categoriesObjectEnum ? Object.values(categoriesObjectEnum) : []);
  }

  useEffect((): void => {
    if (families.length && categories.length && products?.data && products.data.length > 0) {
      productsManager.init(families, categories, products.data);
      setMappedProducts(productsManager.mappedProducts);
    }
  }, [categories, families]);

  const onProductBubbleClick = (product: Product): void => {
    setSelectedProduct(product);
    if (product?.id === currentProduct?.id) {
      dispatch(ordersActions.setCurrentItem(undefined));
      dispatch(ordersActions.setError(undefined));
    }
    if (product.id !== currentProduct?.id) {
      getOneProduct(product.id)
        .unwrap()
        .then((response) => {
          const itemComponents = response?.components
            ?.map((component) => component.componentType)
            .map((componentType) => ({ componentType }));
          dispatch(ordersActions.setCurrentItem({ product: response, itemComponents }));
        })
        .catch((error) => {
          setSelectedProduct(undefined);
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    }
  };

  const onShadeBubbleClick = (shade: Shade): void => {
    dispatch(mapActions.setTeethShade(shade.code as ToothShadeEnum));
  };

  const mapProductsToBubbles = () => {
    return isLoadingGetProducts ? (
      <Skeleton type="circle" height="75px" width="75px" />
    ) : (
      mappedProducts &&
        Object.keys(mappedProducts).map((familyKey) => {
          const categories = mappedProducts[familyKey as keyof MappedProducts];
          if (!categories) return null;

          return Object.keys(categories).map((categoryKey) => {
            const products = categories[categoryKey as keyof typeof categories];
            if (!products) return null;

            return products.map((product: Product) => {
              const productFamilyColor =
                ColorPropsEnum[`FAMILY_${familyKey.toUpperCase()}` as keyof typeof ColorPropsEnum];
              const borderColor = product.id === selectedProduct?.id ? productFamilyColor : 'white';
              return (
                <div className={styles['map-tools__button']} key={product.id}>
                  <BubblePicto
                    isClickable
                    isHoverable
                    hoverBorderColor={productFamilyColor}
                    onClick={() => {
                      onProductBubbleClick(product);
                    }}
                    size="large"
                    backgroundColor={
                      product.id === selectedProduct?.id
                        ? ColorPropsEnum.PURPLE_MEDIUM
                        : ColorPropsEnum.GREY_100
                    }
                    color={borderColor}
                    mainTitle={product[getLocalizedProperty('label') as keyof Product]}
                    mainTitleSize="s"
                    url={product.imageUrl}
                    data-cy={`product-bubble-${product.id}`}
                  />
                </div>
              );
            });
          });
        })
    );
  };

  const mapShadesToBubbles = () => {
    // when several products can be added to the map, this changes
    const toothComponent = currentProduct?.components?.find((component) =>
      [ComponentType.TOOTH, ComponentType.PARTIAL_TOOTH].includes(component.componentType)
    );
    const shadesBubbles = toothComponent?.shades?.map((shade) => {
      const borderColor =
        shade.code === selectedShade ? ColorPropsEnum.PRIMARY : ColorPropsEnum.WHITE;
      if (Object.values(ToothShadeEnum).includes(shade.code as ToothShadeEnum)) {
        return (
          <div className={styles['map-tools__shade-button']} key={shade.id}>
            <BubblePicto
              isClickable
              isHoverable
              type="text"
              hoverBorderColor="primary"
              onClick={() => {
                onShadeBubbleClick(shade);
              }}
              size="medium"
              backgroundColor={shade.code?.toLowerCase()}
              color={borderColor}
              mainTitle={shade.code?.replace('_', '.')}
              mainTitleSize="s"
              data-cy={`shade-bubble-${shade.code?.toLowerCase()}`}
            />
          </div>
        );
      }
    });
    return shadesBubbles;
  };

  return (
    <div className={styles['map-tools']}>
      {toolMode === MapModeEnum.PRODUCT && mapProductsToBubbles()}
      {toolMode === MapModeEnum.SHADES && mapShadesToBubbles()}
    </div>
  );
};
export default MapTools;
