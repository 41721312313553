import { Avatar, Chips, DropdownMenu, Header, Toast } from '@platform-storybook/circlestorybook';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { history } from '../../models/history.tsx';
import { useTranslation } from 'react-i18next';
import style from './private-app.module.scss';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { api } from '../../services/api.ts';
import { useEffect, useState } from 'react';
import { resetAll } from '../../store';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import { authActions } from '../../store/auth/auth.reducers.tsx';
import { connectedUserSelector } from '../../store/auth/auth.selectors.tsx';
import { viteMode } from '../../utils/utils.tsx';
import { isOrderStreamingSelector, orderSelector } from '../../store/orders/orders.selectors.tsx';
import { useEndTaskMutation } from '../../services/manufacturing-orders-api.services.tsx';
import { OrderForUpdate } from '../../models/order.tsx';

const PrivateApp = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  history.navigate = useNavigate();

  const toast = useAppSelector(toastSelector);
  const connectedUser = useAppSelector(connectedUserSelector);
  const [isResetStore, setIsResetStore] = useState<boolean>(false);
  const envLabel = viteMode();
  const isStreaming = useAppSelector(isOrderStreamingSelector);
  const order = useAppSelector(orderSelector);
  const [endTask] = useEndTaskMutation();

  const handleLogoClick = async () => {
    if (isStreaming) {
      await endTask((order as OrderForUpdate).orderNumber as string);
    }
    if (history.navigate) history.navigate('/');
  };

  useEffect(() => {
    dispatch(resetAll());
    dispatch(api.util.resetApiState());
    setIsResetStore(true);
  }, []);

  const getAvatarLetter = () => {
    return connectedUser?.email?.substring(0, 1).toUpperCase();
  };

  const contentAvatarMenu = [
    [{ label: t('link.myProfile'), type: 'nav', link: '/profile', disabled: true }],
    [
      {
        label: t('link.logout'),
        type: 'button',
        onClick: async () => {
          if (isStreaming) {
            await endTask((order as OrderForUpdate).orderNumber as string);
          }
          dispatch(authActions.logout());
        }
      }
    ]
  ];

  return (
    isResetStore && (
      <div className={style['private-app']} data-cy="privateApp">
        {toast && (
          <Toast
            data-cy={`toast-${toast.type}`}
            message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
            onClose={() => {
              dispatch(feedbackActions.resetToast());
            }}
            autoHideDuration={toast.type === 'danger' ? 8000 : 5000}
            type={toast.type}
          />
        )}
        <Header
          logoClass="logo-circle-title-purple"
          color="grey"
          onLogoClick={() => handleLogoClick()}>
          <>
            <div className={style['private-app__header__content']}>
              {envLabel && <Chips firstLabel={envLabel} color={ColorPropsEnum.PRIMARY} />}
            </div>
            <nav className={style['private-app__header__nav']}>
              <DropdownMenu
                renderTargetButton={(props: { active: boolean }) => (
                  <Avatar
                    label={getAvatarLetter() || ''}
                    isActive={props.active}
                    cursorPointer={true}
                    className={style['private-app__header__nav__avatar']}
                  />
                )}
                data={contentAvatarMenu}
              />
            </nav>
          </>
        </Header>

        <main className={style['private-app__main']}>
          <Outlet />
        </main>
      </div>
    )
  );
};
export default PrivateApp;
