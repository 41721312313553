import { PositionKey } from '../enum/component';
import { sortedPositionsNumber } from '../enum/position.enum';

/**
 * For chairside we calculate missingTeeth behind the scenes
 * They are not shown on the map
 * All teeth are missing except adjacent one to the treatment
 *
 * @param tooth
 * @returns array of the missing teeth
 */
export const computeMissingTeeth = (tooth: number | undefined): Array<PositionKey> => {
  const selectedToothIndex = sortedPositionsNumber.indexOf(tooth as PositionKey);
  if (tooth) {
    return sortedPositionsNumber.filter(
      (position, index) =>
        position && (index > selectedToothIndex + 1 || index < selectedToothIndex - 1)
    );
  }
  return [];
};
