export enum ComponentType {
  FRAME = 'FRAME',
  TOOTH = 'TOOTH',
  INFRASTRUCTURE = 'INFRASTRUCTURE',
  GINGIVA = 'GINGIVA',
  PROSTHETIC_STUMP = 'PROSTHETIC_STUMP',
  IMPLANT_ATTACHMENT = 'IMPLANT_ATTACHMENT',
  PROSTHESIS_ATTACHMENT = 'PROSTHESIS_ATTACHMENT',
  WAX_ON_HARD_BASE = 'WAX_ON_HARD_BASE',
  GUARD = 'GUARD',
  PARTIAL_TOOTH = 'PARTIAL_TOOTH',
  MODELS = 'MODELS',
  PINK_GINGIVA = 'PINK_GINGIVA'
}

export enum ManufacturingProcessEnum {
  PRINT = 'PRINT',
  MILLING = 'MILLING',
  CASTING = 'CASTING'
}

export enum MaterialStratificationEnum {
  MONO = 'MONO',
  MULTI = 'MULTI'
}

export enum ToothStratificationTechniqueEnum {
  FULL = 'FULL',
  CUTBACK = 'CUTBACK'
}

export enum ImplantAttachmentEnum {
  BALL = 'BALL',
  LOCATOR = 'LOCATOR',
  MICRO_CONNECTOR = 'MICRO_CONNECTOR',
  CLIP = 'CLIP',
  EQUATOR = 'EQUATOR'
}

export enum ComponentTypeProperties {
  SHADES = 'shades',
  SHAPES = 'shapes',
  ANGULATIONS = 'angulations',
  ASPECTS = 'aspects',
  MATERIALS = 'materials',
  TOOTH_STRATIFICATION_TECHNIQUES = 'toothStratificationTechniques'
}

export enum ItemComponentProperties {
  SHADE = 'shade',
  SHAPE = 'shape',
  ANGULATION = 'angulation',
  ASPECT = 'aspect',
  MATERIAL = 'material',
  TOOTH_STRATIFICATION_TECHNIQUE = 'toothStratificationTechnique'
}

export type PositionKey =
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 47
  | 48;

export enum CommonTypeProperties {
  SHADES = 'shades',
  SHAPES = 'shapes',
  STRUCTURES = 'structures',
  ANGULATIONS = 'angulations',
  ASPECTS = 'aspects',
  MATERIALS = 'materials'
}

export enum GingivaShadeEnum {
  T3 = 'T3',
  T5 = 'T5',
  T7 = 'T7'
}

export enum ToothShapeEnum {
  OVAL = 'OVAL',
  TRIANGULAR = 'TRIANGULAR',
  SQUARE = 'SQUARE', // Equilibree
  ROUND = 'ROUND',
  ROUND_TRIANGULAR = 'ROUND_TRIANGULAR'
}

export enum ToothShadeEnum {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A3_5 = 'A3_5',
  A4 = 'A4',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  D2 = 'D2',
  D3 = 'D3',
  D4 = 'D4',
  METAL = 'METAL'
}

export enum MaterialEnum {
  COMPOSITE = 'COMPOSITE',
  RESIN = 'RESIN',
  ZIRCONIUM = 'ZIRCONIUM',
  LITHIUM_DISILICATE = 'LITHIUM_DISILICATE',
  TITANIUM = 'TITANIUM',
  CHROME_COBALT = 'CHROME_COBALT',
  GOLD = 'GOLD',
  LAYERED_CERAM = 'LAYERED_CERAM',
  FIBER_COMPOSITE = 'FIBER_COMPOSITE',
  PEEK = 'PEEK',
  ACETAL = 'ACETAL',
  ACRYLIC_RESIN = 'ACRYLIC_RESIN',
  BI_MATERIAL_ACETAL = 'BI_MATERIAL_ACETAL'
}

export enum AspectEnum {
  VEINED = 'VEINED',
  UNVEINED = 'UNVEINED'
}

enum BothEnum {
  BOTH = 'both'
}

export enum DentalArchEnum {
  UPPER = 'upper',
  LOWER = 'lower'
}

export const DentalArchEnumWithBoth = { ...DentalArchEnum, ...BothEnum };

export type DentalArchEnumWithBoth =
  (typeof DentalArchEnumWithBoth)[keyof typeof DentalArchEnumWithBoth];

export enum ToothStructureEnum {
  METAL = 'METAL',
  BACKPLATE = 'BACKPLATE',
  ADJUSTED_BACKPLATE = 'ADJUSTED_BACKPLATE',
  ADJUSTED = 'ADJUSTED',
  ADJUSTED_METAL = 'ADJUSTED_METAL'
}
