import {
  ComponentType,
  DentalArchEnum,
  DentalArchEnumWithBoth,
  ToothShadeEnum,
  ToothStructureEnum
} from '../../../enum/component';
import { ToothSelectionEnum } from '../../../enum/map.enum';
import { Position } from '../../../models/map';
import {
  OrderItem,
  OrderItemComponent,
  OrderItemComponentLight,
  OrderItemLight
} from '../../../models/order';
import { FamilyColorEnum } from '../../../enum/color.enum';
import { Family } from '../../../enum/product.enum';
import { Structure } from '../../../models/common-types';
import { PositionKey } from '../../../models/position';
import { OrderedLowerPositions, OrderedUpperPositions } from '../../../enum/position.enum.ts';
import { emptyBubble } from '../../../store/map/map.utils.tsx';
import { publicImagesUrl } from '../../../utils/utils';

/**
 * Retrieves, if one, the desired component of an item
 *
 * @param {item} Item - The item to search in.
 * @param {type} string - The component type desired.
 * @returns {OrderItemComponent | undefined} The OrderItemComponent desired.
 */
export const getComponentInItemByType = (
  item: OrderItem | OrderItemLight,
  type: ComponentType
): OrderItemComponent | OrderItemComponentLight | undefined => {
  return item.itemComponents?.find((component) => type === component.componentType);
};

/**
 * Return the dental arch from the positions
 * @param positions
 */
export const getDentalArchFromPositions = (positions: PositionKey[]): DentalArchEnumWithBoth => {
  const onLowerArch = OrderedLowerPositions.some((pos) => positions?.includes(pos));
  const onUpperArch = OrderedUpperPositions.some((pos) => positions?.includes(pos));

  if (onUpperArch && !onLowerArch) {
    return DentalArchEnumWithBoth.UPPER;
  }

  if (!onUpperArch && onLowerArch) {
    return DentalArchEnumWithBoth.LOWER;
  }

  return DentalArchEnumWithBoth.BOTH;
};

/**
 *
 * @param {DentalArchEnum} arch
 * @returns {boolean}
 */
export const isUpperArch = (arch: DentalArchEnum): boolean => {
  return arch === DentalArchEnum.UPPER;
};

/**
 * Get family color depends on the family product
 * @param {Family} family
 * @returns {FamilyColorEnum}
 */
export const getFamilyColor = (family: Family): FamilyColorEnum => {
  switch (family) {
    case Family.FIXED:
      return FamilyColorEnum.FAMILY_FIXED;
    case Family.GUARDS:
      return FamilyColorEnum.FAMILY_GUARDS;
    case Family.REMOV:
      return FamilyColorEnum.FAMILY_REMOV;
    case Family.IMPLANT:
      return FamilyColorEnum.FAMILY_IMPLANT;
    case Family.OCCLUSION_RIMS:
      return FamilyColorEnum.FAMILY_OCR;
  }
};

export const removeTeethStructureToMap = (
  statePosition: Position,
  teethShade: ToothShadeEnum | undefined
): Position => {
  const statePositionCopy = { ...statePosition };
  if (statePositionCopy.teethStructure) {
    statePositionCopy.svgLayers = statePositionCopy.svgLayers?.filter(
      (layer) => !Object.keys(ToothStructureEnum).includes(layer)
    );
    if (
      statePositionCopy.svgLayers &&
      Object.values([
        ToothStructureEnum.ADJUSTED,
        ToothStructureEnum.ADJUSTED_BACKPLATE,
        ToothStructureEnum.ADJUSTED_METAL
      ]).includes(statePositionCopy.teethStructure.code)
    ) {
      statePositionCopy.svgLayers.push(ComponentType.GINGIVA.toString());
    }
    statePositionCopy.teethShade = teethShade;
    statePositionCopy.teethStructure = undefined;
    statePositionCopy.bubble = emptyBubble;
    statePositionCopy.structureSelection = ToothSelectionEnum.SELECTABLE;
  }
  return statePositionCopy;
};

export const addTeethStructureToMap = (
  structure: Structure,
  statePosition: Position,
  teethShade?: ToothShadeEnum
): Position => {
  const statePositionCopy = removeTeethStructureToMap(statePosition, teethShade);
  statePositionCopy.teethStructure = structure;
  statePositionCopy.structureSelection = ToothSelectionEnum.SELECTED;
  if (
    statePositionCopy.svgLayers &&
    Object.values([
      ToothStructureEnum.ADJUSTED,
      ToothStructureEnum.ADJUSTED_BACKPLATE,
      ToothStructureEnum.ADJUSTED_METAL
    ]).includes(structure.code)
  ) {
    // Remove gingiva for adjusted structures
    statePositionCopy.svgLayers = statePositionCopy.svgLayers.filter(
      (layer) => layer !== ComponentType.GINGIVA
    );
    // Set adjusted structures
    statePositionCopy.svgLayers.push(ToothStructureEnum.ADJUSTED);
  }
  if (
    statePositionCopy.svgLayers &&
    [ToothStructureEnum.BACKPLATE, ToothStructureEnum.ADJUSTED_BACKPLATE].includes(structure.code)
  ) {
    // Set adjusted structures
    statePositionCopy.svgLayers.push(ToothStructureEnum.BACKPLATE);
  }
  if ([ToothStructureEnum.METAL, ToothStructureEnum.ADJUSTED_METAL].includes(structure.code)) {
    // Set metal teeth shade
    statePositionCopy.teethShade = ToothShadeEnum.METAL;
  }
  statePositionCopy.bubble = {
    ...emptyBubble,
    url: `${publicImagesUrl}structures/${structure.code}.svg`
  };
  return statePositionCopy;
};
