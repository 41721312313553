export enum MapModeEnum {
  PRODUCT = 'product',
  SHADES = 'shades'
}

export enum ToothSelectionEnum {
  SELECTED = 'selected',
  SELECTABLE = 'selectable',
  UNSELECTABLE = 'unselectable'
}

export enum SelectionContextEnum {
  RANGE_STARTED = 'range-started',
  RANGE_ENDED = 'range-ended',
  SINGLE_TOOTH = 'single-tooth',
  ZONE_STARTED = 'zone-started',
  ZONE_ENDED = 'zone-ended'
}

export enum ZoneLinkEnum {
  START = 'start',
  END = 'end',
  END_START = 'end-start' // zone contains a single tooth which two links will begin and end
}

export enum ToolEnum {
  MISSING = 'MISSING',
  EXTRACT = 'EXTRACT',
  STUMP = 'STUMP'
}

export enum CursorEnum {
  POINTER = 'pointer',
  NOT_ALLOWED = 'not-allowed',
  START = 'start',
  END = 'end',
  ADD = 'add',
  REMOVE = 'remove'
}

/**
 * Object containing not allowed cursor for all keyed tooth numbers.
 *
 * @param cursor which cursors to get
 * @return {Object<number, CursorEnum>}
 */
export const getCursors = (cursor: CursorEnum): { [key: number]: CursorEnum } => {
  return {
    18: cursor,
    17: cursor,
    16: cursor,
    15: cursor,
    14: cursor,
    13: cursor,
    12: cursor,
    11: cursor,
    21: cursor,
    22: cursor,
    23: cursor,
    24: cursor,
    25: cursor,
    26: cursor,
    27: cursor,
    28: cursor,
    48: cursor,
    47: cursor,
    46: cursor,
    45: cursor,
    44: cursor,
    43: cursor,
    42: cursor,
    41: cursor,
    31: cursor,
    32: cursor,
    33: cursor,
    34: cursor,
    35: cursor,
    36: cursor,
    37: cursor,
    38: cursor
  };
};

export enum SVGLayerEnum {
  ABUTMENT = 'ABUTMENT',
  ABUTMENT_MULTI_UNIT = 'ABUTMENT_MULTI_UNIT',
  ADJUSTED = 'ADJUSTED',
  ADJUSTED_METAL = 'ADJUSTED_METAL',
  ATTACHMENT_BALL = 'ATTACHMENT_BALL',
  ATTACHMENT_CLIP = 'ATTACHMENT_CLIP',
  ATTACHMENT_EQUATOR = 'ATTACHMENT_EQUATOR',
  ATTACHMENT_LOCATOR = 'ATTACHMENT_LOCATOR',
  ATTACHMENT_MICRO_CONNECTOR = 'ATTACHMENT_MICRO_CONNECTOR',
  BACKPLATE = 'BACKPLATE',
  BRIDGE = 'BRIDGE',
  DOUBLE_BAR = 'DOUBLE_BAR',
  DOUBLE_ZONES = 'DOUBLE_ZONES',
  DRILLING_SYSTEM = 'DRILLING_SYSTEM',
  ENDOCROWN = 'ENDOCROWN',
  EXTRACT = 'EXTRACT',
  FRAME = 'FRAME',
  GINGIVA = 'GINGIVA',
  GUARD_INJECTION = 'GUARD_INJECTION',
  GUARD_LINE = 'GUARD_LINE',
  GUARD_ROUND = 'GUARD_ROUND',
  GUARD_TOOTH = 'GUARD_TOOTH',
  IMPLANT = 'IMPLANT',
  INLAY = 'INLAY',
  INLAYCORE = 'INLAYCORE',
  LINES = 'LINES',
  MISSING = 'MISSING',
  ONLAY = 'ONLAY',
  OVERLAY = 'OVERLAY',
  PIERCING_SYSTEM = 'PIERCING_SYSTEM',
  REINFORCEMENT_SCREW_RETAINED = 'REINFORCEMENT_SCREW_RETAINED',
  SCREW_HEALING = 'SCREW_HEALING',
  SCREW_RETAINED = 'SCREW_RETAINED',
  SIMPLE_BAR = 'SIMPLE_BAR',
  SLEEVE = 'SLEEVE',
  STUMP = 'STUMP',
  TOOTH = 'TOOTH',
  VENEER = 'VENEER',
  WAX_ON_HARD_BASE = 'WAX_ON_HARD_BASE'
}
