import { ToothSelectionEnum } from '../../enum/map.enum';
import { sortedLowerPositionsArray, sortedUpperPositionsArray } from '../../enum/position.enum';
import { PositionKeyString } from '../../models/position';
import { PositionsObj, ProductBubble, ProductCompatibilitiesContext } from '../../models/map';
import { publicImagesUrl } from '../../utils/utils';
import { SimpleColorsEnum } from '../../enum/color.enum.ts';

/**
 * Return forbidden positions array depends on the rule allowSameProductOnArch
 * @param {PositionsObj} positions
 * @param {number} productId
 * @returns {Array<PositionKeyString>}
 */
export const computeForbiddenPositionsSameProductOnArch = (
  positions: PositionsObj,
  productId: number
) => {
  const forbiddenPositions: PositionKeyString[] = [];

  for (const position of sortedUpperPositionsArray) {
    if (positions[position].productIds.some((product) => product.productId === productId)) {
      forbiddenPositions.push(...sortedUpperPositionsArray);
      break;
    }
  }

  for (const position of sortedLowerPositionsArray) {
    if (positions[position].productIds.some((product) => product.productId === productId)) {
      forbiddenPositions.push(...sortedLowerPositionsArray);
      break;
    }
  }

  return forbiddenPositions;
};

/**
 * Return Positions with selection tooth 'selected' 'unselectable' 'selectable'...
 * @param position
 * @param positions
 * @param productCompatibilities
 * @param forbiddenPositions
 * @returns PositionsObj
 */
export const computeSelectionToothPositions = (
  position: number,
  positions: PositionsObj,
  productCompatibilities: ProductCompatibilitiesContext,
  forbiddenPositions: PositionKeyString[]
): PositionsObj => {
  const newPositions = { ...positions };
  Object.keys(newPositions).forEach((positionKey: string) => {
    if (newPositions[positionKey].selection !== ToothSelectionEnum.SELECTED) {
      let productIdsOnPosition: number[] = [];
      if (newPositions[positionKey]?.productIds?.length > 0)
        productIdsOnPosition = newPositions[positionKey]?.productIds?.map(
          (productId) => productId.productId
        );

      const isMissingToothOnPosition: boolean = newPositions[positionKey].missing;
      const isSameProductOnPosition: boolean = productIdsOnPosition.includes(position);
      const notCompatibleProductOnPosition: boolean =
        productCompatibilities.notCompatibleToothProducts.some((id) =>
          productIdsOnPosition.includes(id)
        );
      if (
        // Not possible to select a tooth where there is missing
        isMissingToothOnPosition ||
        // Not possible to select a tooth where there is the same product
        isSameProductOnPosition ||
        // Not possible to select a tooth where there is a non compatible product on tooth
        notCompatibleProductOnPosition ||
        // Not possible to select a tooth which has a forbidden positions computed before
        [...new Set(forbiddenPositions)].includes(positionKey as PositionKeyString)
      ) {
        newPositions[positionKey] = {
          ...newPositions[positionKey],
          selection: ToothSelectionEnum.UNSELECTABLE
        };
      } else {
        newPositions[positionKey] = {
          ...newPositions[positionKey],
          selection: ToothSelectionEnum.SELECTABLE
        };
      }
    }
  });
  return newPositions;
};

export const emptyBubble: ProductBubble = {
  type: 'product',
  size: 'small',
  backgroundColor: SimpleColorsEnum.WHITE,
  color: SimpleColorsEnum.GREY_100,
  url: `${publicImagesUrl}structures/EMPTY.svg`
};
