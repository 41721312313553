import { useEffect, useRef, useState } from 'react';
import styles from './stream-feature.module.scss';
import { useTranslation } from 'react-i18next';
import { ProgressBar, Text } from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../../store/hooks';
import { ordersActions } from '../../../../store/orders/orders.reducer';
import { VncViewer, VncViewerState } from '@anatoscope/react-vncviewer';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import { StreamingParams } from '../../../../models/streaming';

type props = {
  streamingParams: StreamingParams | undefined;
  onCloseStreaming: () => void;
};

const StreamFeature = ({ streamingParams, onCloseStreaming }: props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();
  const [vncState, setVncState] = useState(VncViewerState.disconnected);
  const vncViewerRef = useRef<React.ElementRef<typeof VncViewer>>(null);

  useEffect(() => {
    if (vncState === VncViewerState.connected) {
      dispatch(ordersActions.setIsStreaming(true));
    }
  }, [vncState]);

  return (
    <>
      {!streamingParams && (
        <div className={styles['stream-feature__wait-screen']}>
          <ProgressBar duration={16}>
            <div className={styles['stream-feature__wait-screen__text']}>
              <Text
                color={ColorPropsEnum.PRIMARY}
                label={t('createOrder.design.loading.title')}
                size="s"
                textAlign="center-align"
                type="title"
              />
              <Text
                textAlign="center-align"
                color={ColorPropsEnum.GREY}
                label={t('createOrder.design.loading.text')}
                size="m"
              />
              <Text
                color={ColorPropsEnum.GREY}
                label={t('createOrder.design.loading.load')}
                size="m"
                textAlign="center-align"
                className={styles['stream-feature__wait-screen__text__loading']}
              />
            </div>
          </ProgressBar>
        </div>
      )}
      {streamingParams && (
        <div className={styles['stream-feature__vnc-container']}>
          <VncViewer
            autoConnect={true}
            className={styles['stream-feature__vnc-container__viewer']}
            ref={vncViewerRef}
            url={`wss://${streamingParams?.host}:${streamingParams?.port}/${streamingParams?.path}`}
            password={streamingParams?.password}
            showMenu={false}
            onConnectionStateChange={(state) => setVncState(state)}
            scaleViewport={true}
            blurOnFocusLoss={false}
            onConnectionLost={onCloseStreaming}
          />
        </div>
      )}
    </>
  );
};
export default StreamFeature;
