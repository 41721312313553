import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OrderFile,
  OrderForCreation,
  OrderForUpdate,
  OrderItemLight,
  OrdersReducer
} from '../../models/order';
import { DiagnosticCreation } from '../../models/patient';

const initialOrder: OrdersReducer = {
  order: {
    orderNumber: undefined,
    dentistName: undefined,
    dentistEmail: undefined,
    clinicName: undefined,
    clinicId: undefined,
    toManufacture: true,
    patient: { reference: undefined, diagnostic: undefined },
    expectedDate: undefined,
    instructions: undefined,
    labId: undefined,
    labName: undefined
  },
  files: [],
  items: [],
  error: '',
  currentItem: undefined,
  isStreaming: false
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrder,
  reducers: {
    setOrder: (state, action: PayloadAction<OrderForCreation | OrderForUpdate>) => {
      state.order = action.payload;
    },
    setDiagnostic: (state, action: PayloadAction<DiagnosticCreation>) => {
      state.order = {
        ...state.order,
        patient: {
          ...state.order.patient,
          diagnostic: action.payload
        }
      };
    },
    setFiles: (state, action: PayloadAction<OrderFile[]>) => {
      state.files = action.payload;
    },
    resetFiles: (state) => {
      state.files = initialOrder.files;
    },
    setCurrentItem: (state, action: PayloadAction<OrderItemLight | undefined>) => {
      state.currentItem = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setIsStreaming: (state, action: PayloadAction<boolean>) => {
      state.isStreaming = action.payload;
    },
    resetOrder: () => initialOrder
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrdersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialOrder };
    });
  }
});

export const ordersActions = ordersSlice.actions;
