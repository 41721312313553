import { Skeleton } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../../../enum/color';
import styles from './skeleton-orders-list.module.scss';
type Props = {
  count: number;
};

const SkeletonOrdersList = ({ count }: Props) => {
  const skeletonArray = [];
  for (let i = 0; i < count; i++) {
    skeletonArray.push(
      <Skeleton
        backgroundColor={ColorPropsEnum.GREY_100}
        className={styles['skeleton-orders-list']}
        key={i}
        width="300px"
        height="350px"
      />
    );
  }

  return skeletonArray;
};

export default SkeletonOrdersList;
