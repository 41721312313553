import { createSelector } from '@reduxjs/toolkit';
import { MapReducer, Position } from '../../models/map';
import { ToothShadeEnum } from '../../enum/component';
import { OrdersReducer } from '../../models/order';

import {
  CursorEnum,
  MapModeEnum,
  getCursors,
  SelectionContextEnum,
  ToothSelectionEnum
} from '../../enum/map.enum';
import { PositionKeyString } from '../../models/position';
import { FamilyColorEnum } from '../../enum/color.enum';
import { TeethMode } from '../../enum/product.enum';
import { currentItemSelector } from '../orders/orders.selectors';

interface ReducerState {
  mapState: MapReducer;
  ordersState: OrdersReducer;
}

interface ComponentsForTeethMap {
  framePositions: number[][];
  stumpPositions: number[][];
}

const positionsGroupedByProductId = (
  positions: Array<Position>
): { [key: string]: Array<number> } => {
  return positions.reduce((groupedPositions: Record<string, number[]>, position: Position) => {
    if (position.productIds?.length) {
      position.productIds.forEach((productId) => {
        if (!groupedPositions[productId.uniqueProductId]) {
          groupedPositions[productId.uniqueProductId] = [];
        }
        groupedPositions[productId.uniqueProductId].push(+(position.notation as PositionKeyString));
      });
    } else if (ToothSelectionEnum.SELECTED === position.selection) {
      if (!groupedPositions['-1']) {
        groupedPositions['-1'] = [];
      }
      groupedPositions['-1'].push(+(position.notation as PositionKeyString));
    }
    return groupedPositions;
  }, {});
};

/**
 * Computes all array of values for components needed for the patientMouth in teeth map to work
 * In short , all position number must be sorted in array by productId
 *
 * @param {Position[]} positions
 * @returns {ComponentsForTeethMap}
 */
const computeComponentsForTeethMapByProduct = (
  positions: Array<Position>
): ComponentsForTeethMap => {
  const framePositions = positionsGroupedByProductId(
    positions.filter((position) => position.frame)
  );
  const stumpPositions = positionsGroupedByProductId(
    positions.filter((position) => position.stump)
  );

  return {
    framePositions: Object.keys(framePositions).map((productId) => framePositions[productId]),
    stumpPositions: Object.keys(stumpPositions).map((productId) => stumpPositions[productId])
  };
};

export const mapSelector = (state: ReducerState) => {
  return state?.mapState?.positions;
};

export const mapContextSelector = (state: ReducerState) => {
  return state?.mapState?.mapContext;
};

const mapTeethShades = (
  positions: Array<Position>
): { [key: string]: Array<number | undefined> } => {
  const shades: { [key: string]: Array<number | undefined> } = {};
  for (const shade in ToothShadeEnum) {
    const value = positions
      .filter((position) => shade === position.teethShade)
      .map((position) => (position?.notation ? +position.notation : undefined));
    if (value?.length) {
      shades[shade.toLowerCase()] = value;
    }
  }

  return shades;
};

// Build Svg layer object for patientMouth storybook (EXTRACT, INLAY, STUMP...)
export const mapSvgLayerSelector = createSelector(
  [mapSelector],
  (
    positions
  ): {
    TOOTH?: number[][];
    EXTRACT: number[];
    MISSING: number[];
    STUMP: number[][];
    FRAME: number[][];
  } => {
    const positionList = Object.values(positions);
    const componentForTeethMap = computeComponentsForTeethMapByProduct(positionList);

    // Initialize the result object
    const computingSvgLayers: {
      [key: string]: {
        notations: number[];
        uniqueProductId: string;
      }[];
    } = {};

    // Loop through each entry in the object
    Object.values(positionList).forEach((position) => {
      const notation = parseInt(position.notation!);
      const svgLayers = position['svgLayers'];

      let uniqueProductIds = position?.productIds?.map((product) => product.uniqueProductId);

      if (uniqueProductIds.length === 0) {
        // add temporary id for product being added on the map
        uniqueProductIds = ['tempId'];
      }

      svgLayers?.forEach((layer) => {
        if (!computingSvgLayers[layer]) {
          computingSvgLayers[layer] = []; // Initialize the notation array for this layer if it doesn't exist
        }

        uniqueProductIds.forEach((uniqueProductId) => {
          // Find the appropriate array range for this uniqueProductId
          const notationRange = computingSvgLayers[layer].find(
            (notation) => notation.uniqueProductId === uniqueProductId
          );

          if (notationRange) {
            notationRange.notations.push(notation); // Add the notation to the existing range
          } else {
            computingSvgLayers[layer].push({ uniqueProductId, notations: [notation] }); // Create a new range
          }
        });
      });
    });

    // Remove uniqueProductId keys in computingSvgLayers object
    const svgLayersMapping = Object.keys(computingSvgLayers).reduce(
      (acc: { [key: string]: number[][] }, key) => {
        acc[key] = computingSvgLayers[key].map((group) => group.notations);
        return acc;
      },
      {}
    );

    return {
      ...svgLayersMapping,
      EXTRACT: positionList
        .map((position) => (position.extract && position.notation ? +position.notation : undefined))
        .filter((element) => element !== undefined),
      MISSING: positionList
        .map((position) => (position.missing && position.notation ? +position.notation : undefined))
        .filter((element) => element !== undefined),
      STUMP: componentForTeethMap.stumpPositions,
      FRAME: componentForTeethMap.framePositions
    };
  }
);

// Get all selected tooth positions
export const selectedTeethSelector = createSelector([mapSelector], (positions): Array<Position> => {
  const positionList = Object.values(positions);
  const selectedPositions: Array<Position> = [];

  // Loop through each entry in the object
  Object.values(positionList).forEach((position) => {
    if (position.selection === ToothSelectionEnum.SELECTED) {
      selectedPositions.push(position);
    }
  });

  return selectedPositions;
});

export const teethShadesSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return mapTeethShades(positionList);
});

export const chairsideCursorsSelector = createSelector(
  [mapSelector, mapContextSelector, currentItemSelector],
  (positions, mapContext) => {
    if (mapContext?.mode === MapModeEnum.SHADES) {
      return { ...getCursors(CursorEnum.POINTER) };
    }
    const positionList = Object.values(positions);

    const cursors: { [key: number]: CursorEnum } = { ...getCursors(CursorEnum.ADD) };
    Object.values(positionList).forEach((position) => {
      if (position.selection === ToothSelectionEnum.SELECTED) {
        cursors[+(position.notation as string)] = CursorEnum.REMOVE;
      }
    });
    return cursors;
  }
);

export const cursorsSelector = createSelector(
  [mapSelector, mapContextSelector, currentItemSelector],
  (positions, mapContext, currentItem) => {
    if (mapContext?.mode === MapModeEnum.SHADES) {
      return { ...getCursors(CursorEnum.POINTER) };
    }
    const cursors: { [key: number]: CursorEnum } = { ...getCursors(CursorEnum.NOT_ALLOWED) };

    Object.keys(positions).forEach((position) => {
      switch (positions[position].selection) {
        case ToothSelectionEnum.UNSELECTABLE:
          cursors[+position] = CursorEnum.NOT_ALLOWED;
          break;
        case ToothSelectionEnum.SELECTABLE:
          if (mapContext?.teethSelectionMode === TeethMode.SINGLE_TOOTH) {
            cursors[+position] = currentItem?.product ? CursorEnum.ADD : CursorEnum.POINTER;
          } else if (
            !mapContext?.userAction ||
            mapContext?.userAction === SelectionContextEnum.RANGE_ENDED ||
            mapContext?.userAction === SelectionContextEnum.ZONE_ENDED
          ) {
            cursors[+position] = CursorEnum.START;
          } else if (
            mapContext?.userAction === SelectionContextEnum.RANGE_STARTED ||
            mapContext?.userAction === SelectionContextEnum.ZONE_STARTED
          ) {
            cursors[+position] = CursorEnum.END;
          } else {
            cursors[+position] = CursorEnum.POINTER;
          }
          break;
        case ToothSelectionEnum.SELECTED:
          if (
            mapContext?.userAction === SelectionContextEnum.ZONE_STARTED &&
            position === mapContext.start
          ) {
            cursors[+position] = CursorEnum.END;
          }
          if (mapContext?.teethSelectionMode === TeethMode.SINGLE_TOOTH) {
            cursors[+position] = CursorEnum.REMOVE;
          }
          break;
      }
    });
    return cursors;
  }
);

export const lineAndNumberColorsSelector = createSelector([mapSelector], (positions) => {
  const positionList = Object.values(positions);
  return {
    'family-remov': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_REMOV)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-fixed': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_FIXED)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-implant': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_IMPLANT)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-guards': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_GUARDS)
      .map((position) => (position?.notation ? +position.notation : undefined)),
    'family-ocr': positionList
      .filter((position) => position.familyColor === FamilyColorEnum.FAMILY_OCR)
      .map((position) => (position?.notation ? +position.notation : undefined))
  };
});
