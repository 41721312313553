import { api } from './api';
import { FullProduct, ProductListResponse } from '../models/product';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllChairsideProducts: builder.query<ProductListResponse, void>({
      query: () => ({
        url: '/products?limit=-1&filter.deletedAt=$null&filter.isChairSide=1',
        method: 'GET'
      })
    }),
    getOneProduct: builder.query<FullProduct, number>({
      query: (productId: number) => ({
        url: `/products/${productId}`,
        method: 'GET'
      })
    })
  })
});

export const { useGetAllChairsideProductsQuery, useLazyGetOneProductQuery } = productsApi;
