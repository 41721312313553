import styles from './treatment-type-modal.module.scss';
import { Button, Text, TextField } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import TreatmentTypeRadioList from './treatment-type-radio-list/TreatmentTypeRadioList';
import moment from 'moment';
import Modal from '../../../../features/modal/Modal';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../utils/useForm';

type props = {
  isOpened: boolean;
  closeOnOutsideClick?: boolean;
  onOutsideClick: () => void;
  onSubmit: (reference: string) => void;
};

const TreatmentTypeModal = ({
  isOpened,
  closeOnOutsideClick = true,
  onOutsideClick,
  onSubmit
}: props) => {
  const { t } = useTranslation(['treatments']);

  const submit = () => {
    onSubmit(values.reference as string);
  };

  const { values, errors, handleSubmit, handleSelect, handleChange } = useForm(
    {
      reference: 'PATIENT_' + moment().format('YYMMDD'),
      treatmentType: 'one'
    },
    submit
  );

  return (
    <Modal
      isOpened={isOpened}
      onOutsideClick={onOutsideClick}
      closeOnOutsideClick={closeOnOutsideClick}>
      <>
        <Text
          size="m"
          textAlign="center-align"
          type="title"
          label={t('treatmentTypeModal.title')}
          color={ColorPropsEnum.PRIMARY}
          bold
        />
        <Text
          size="s"
          textAlign="center-align"
          label={t('treatmentTypeModal.helper')}
          color={ColorPropsEnum.GREY}
          className={styles['treatment-type-modal__helper']}
        />
        <form className={styles['treatment-type-modal__input__form']}>
          <TreatmentTypeRadioList
            options={[
              {
                value: 'one',
                logoClass: 'logo-one',
                label: t('treatmentTypeModal.options.one.description')
              },
              {
                value: 'inlab',
                isDisabled: true,
                logoClass: 'logo-inlab',
                label: t('treatmentTypeModal.options.inlab.description')
              }
            ]}
            selectedValue={values.treatmentType as string}
            onClick={(newValue: string) => {
              handleSelect(newValue, 'treatmentType');
            }}
          />

          <TextField
            label={t('treatmentTypeModal.reference.title')}
            labelColor={ColorPropsEnum.GREY}
            id="reference"
            name="reference"
            value={values.reference}
            type="text"
            helperText={
              errors.reference
                ? t('treatmentTypeModal.reference.error')
                : t('treatmentTypeModal.reference.helper')
            }
            className={styles['treatment-type-modal__input__form__textfield']}
            onChange={handleChange}
            variant={errors.reference ? 'danger' : 'default'}
            radius="full"
            isFocused
            data-cy={'treatment-type-modal-reference'}
          />
          <Button
            label={t('treatmentTypeModal.submit')}
            onClick={handleSubmit}
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            className={styles['treatment-type-modal__input__form__submit']}
            data-cy={'treatment-type-modal-submit'}
          />
        </form>
      </>
    </Modal>
  );
};

export default TreatmentTypeModal;
