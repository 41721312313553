import { ColorPropsEnum } from '../../../../enum/color';
import { OrderForList } from '../../../../models/order';
import moment from 'moment';
import { Chips, BubblePicto, Text, Button, Carousel } from '@platform-storybook/circlestorybook';
import { forwardRef, LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  WorkflowModelingStepEnum,
  WorkflowPostModelingStepEnum,
  WorkflowStepEnum,
  WorkFlowStepPreModelingEnum
} from '../../../../enum/workflow-step';
import { isOrderChairsideCompliant } from '../../../../utils/orders.utils';
import styles from './order-card.module.scss';

type Props = {
  order: OrderForList;
};

const OrderCard = forwardRef(({ order }: Props, ref: LegacyRef<HTMLElement> | undefined) => {
  const { t } = useTranslation();

  const mode = isOrderChairsideCompliant(order) ? 'one' : 'inlab';

  const displayButton = (currentStep: WorkflowStepEnum | undefined) => {
    switch (currentStep) {
      case WorkflowStepEnum.MODELING:
      case WorkflowStepEnum.MODELING_DESIGN:
      case WorkflowStepEnum.MANUFACTURING:
        return true;
      default:
        return false;
    }
  };

  const getLabelButton = (
    currentStep:
      | WorkFlowStepPreModelingEnum
      | WorkflowModelingStepEnum
      | WorkflowPostModelingStepEnum
      | undefined
  ) => {
    switch (currentStep) {
      case WorkflowStepEnum.MODELING:
      case WorkflowStepEnum.MODELING_DESIGN:
        return t('button.label.design', { ns: 'treatments' });
      case WorkflowStepEnum.MANUFACTURING:
        return t('button.label.manufacture', { ns: 'treatments' });
      default:
        return undefined;
    }
  };

  const getTextColor = () => (mode === 'one' ? ColorPropsEnum.BLACK : ColorPropsEnum.WHITE);

  const getBackgroundColor = () =>
    mode === 'inlab' ? ColorPropsEnum.PURPLE_MEDIUM : ColorPropsEnum.WHITE;

  return (
    <article className={styles['order-card']} ref={ref} data-cy="treatments-card">
      <header
        className={[styles['order-card__header'], styles[`order-card__header--${mode}`]].join(' ')}>
        <BubblePicto
          data-cy="treatments-card-picto"
          count={order.items?.length <= 1 ? undefined : order.items.length}
          url={order.items?.length > 0 ? order.items[0].product.imageUrl : undefined}
          backgroundColor={getBackgroundColor()}
          color={
            order.items?.length > 0
              ? ColorPropsEnum[
                  `FAMILY_${order.items[0].product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                ]
              : undefined
          }
        />
        <div>
          <Text
            data-cy={`treatments-card-${order.id}-ref`}
            type="title"
            size="xs"
            label={order?.patientReference}
            bold={true}
            color={getTextColor()}
            className={styles['order-card__header__title']}
          />
          <Text
            data-cy={`treatments-card-${order.id}-date`}
            size="s"
            label={moment(order.creationDate).format('MM/DD/YY')}
            color={getTextColor()}
          />
        </div>
      </header>
      <div className={styles['order-card__section']}>
        <div className={styles['order-card__section__chips']}>
          {order?.productsEn && order?.productsEn?.length > 0 && (
            <Chips
              data-cy={`treatments-card-${order.id}-product`}
              firstLabel={order?.productsEn[0]}
              secondLabel={
                order?.teethPositions && order?.teethPositions?.length > 0
                  ? order?.teethPositions[0].toString()
                  : '-'
              }
              color={ColorPropsEnum.BLACK}
            />
          )}
          {order.manufacturingProcesses && (
            <Chips
              data-cy={`treatments-card-${order.id}-manufacturing-processes`}
              firstLabel={t(`manufacturingProcess.${order.manufacturingProcesses[0]}`, {
                ns: 'component'
              })}
              color={ColorPropsEnum.WHITE}
            />
          )}

          {order?.shades && order?.shades?.length > 0 && (
            <Chips
              data-cy={`treatments-card-${order.id}-shade`}
              firstLabel={order.shades[0]}
              color={order.shades[0].toString().toLowerCase()}
            />
          )}
        </div>
        {order.thumbnailUrls?.length && (
          <Carousel
            urls={order.thumbnailUrls}
            imageClassName={styles['order-card__section__carousel']}
          />
        )}
        <div className={styles['order-card__section__button']}>
          {displayButton(order.currentStep) && (
            <Button label={getLabelButton(order.currentStep)} isDisabled={true} />
          )}
        </div>
      </div>
    </article>
  );
});

OrderCard.displayName = 'OrderCard';

export default OrderCard;
