export enum ColorEnum {
  PURPLE_DARK = '#2A1565',
  PURPLE_MEDIUM = '#6D42EB',
  PURPLE_SUPER_LIGHT = '#E9E8FF'
}

export enum ColorPropsEnum {
  DEFAULT = 'default',
  BLACK = 'black',
  WHITE = 'white',
  GREY = 'grey',
  GREY_100 = 'grey-100',
  PRIMARY = 'primary',
  PURPLE = 'purple',
  PURPLE_SUPER_LIGHT = 'purple-super-light',
  PURPLE_LIGHT = 'purple-light',
  PURPLE_MEDIUM = 'purple-medium',
  FAMILY_FIXED = 'family-fixed',
  FAMILY_REMOV = 'family-remov',
  FAMILY_IMPLANT = 'family-implant',
  FAMILY_GUARDS = 'family-guards',
  FAMILY_OCR = 'family-ocr',
  DANGER = 'danger',
  SUCCESS = 'success'
}
