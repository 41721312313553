import { Modebox } from '@platform-storybook/circlestorybook';
import { useEffect, useState } from 'react';
import { mapActions } from '../../../../../store/map/map.reducer';
import { MapModeEnum } from '../../../../../enum/map.enum';
import { selectedTeethSelector, teethShadesSelector } from '../../../../../store/map/map.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useTranslation } from 'react-i18next';

const ModeBoxPlan = () => {
  const { t } = useTranslation(['order']);
  const selectedTeeth = useAppSelector(selectedTeethSelector);
  const teethShades = useAppSelector(teethShadesSelector);
  const dispatch = useAppDispatch();

  const [modeboxData, setModeboxData] = useState([
    { label: t('createOrder.plan.modeBox.prosthesis'), isValid: false, isDisabled: false },
    { label: t('createOrder.plan.modeBox.teethShade'), isValid: false, isDisabled: true }
  ]);
  const [activeIdModbox, setActiveIdModebox] = useState(0);

  const prosthesisStepIsValid: boolean = selectedTeeth.length === 1;
  const teethShadesStepIsValid: boolean = Object.keys(teethShades).length > 0;

  useEffect(() => {
    // Change modebox status
    if (prosthesisStepIsValid && !teethShadesStepIsValid) {
      setModeboxData([
        { label: t('createOrder.plan.modeBox.prosthesis'), isValid: true, isDisabled: false },
        { label: t('createOrder.plan.modeBox.teethShade'), isValid: false, isDisabled: false }
      ]);
    } else if (prosthesisStepIsValid && teethShadesStepIsValid) {
      setModeboxData([
        { label: t('createOrder.plan.modeBox.prosthesis'), isValid: true, isDisabled: false },
        { label: t('createOrder.plan.modeBox.teethShade'), isValid: true, isDisabled: false }
      ]);
    } else if (!prosthesisStepIsValid) {
      setModeboxData([
        { label: t('createOrder.plan.modeBox.prosthesis'), isValid: false, isDisabled: false },
        { label: t('createOrder.plan.modeBox.teethShade'), isValid: false, isDisabled: true }
      ]);
    }
  }, [prosthesisStepIsValid, teethShadesStepIsValid]);

  const handleClickModebox = (id: number) => {
    // Change active item modebox and MapTools mode (prosthesis / teeth shade)
    if (id === 0) {
      dispatch(
        mapActions.setMapContext({
          mode: MapModeEnum.PRODUCT
        })
      );
      setActiveIdModebox(id);
    } else if (id === 1) {
      dispatch(
        mapActions.setMapContext({
          mode: MapModeEnum.SHADES
        })
      );
      setActiveIdModebox(id);
    }
  };
  return (
    <Modebox
      data-cy="modebox"
      activeIndex={activeIdModbox}
      data={modeboxData}
      onClick={(id: number) => handleClickModebox(id)}
    />
  );
};

export default ModeBoxPlan;
